import React from 'react'
import Footer from '../Footer'
import Header from '../Header'
import styles from './HomePlaceholder.module.css'

export default function HomePlaceholder () {
	return (
		<div className={styles.root}>
			<Header />

			<div className={styles.content}>
				<div className={styles.topText}>The Inaugural</div>

				<div className={styles.text} />

				<div className={styles.bottomText}>
					Coming Soon
				</div>

				<div className={styles.moreText}>
					<p>
						<strong>Hosted By Actor and Activist Jay Ellis</strong>
					</p>

					<p>
						With Call to Action Speeches and Special Appearances From <strong>Dr.
						Jane Goodall, Naomi Osaka, Adam McKay, Megan Rapinoe</strong> And Many
						More To Be Announced!
					</p>

					<p style={{ textTransform: 'uppercase', fontSize: 26 }}>
						Monday, February 28, 5pm ET
					</p>
				</div>
			</div>

			<Footer />
		</div>
	)
}
