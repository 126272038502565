import React from 'react'
import styles from './PartnerList.module.scss'

const logoContext = require.context('./img/anthem/logos', true, /\.svg$/)

const links = {
	// foundational
	adcouncil: 'https://www.adcouncil.org/',
	bornthisway: 'https://bornthisway.foundation/',
	feedingamerica: 'https://www.feedingamerica.org/',
	glaad: 'https://www.glaad.org/',
	mozilla: 'https://foundation.mozilla.org/en/',
	naacp: 'https://naacp.org/',
	nrdc: 'https://www.nrdc.org/',
	wwf: 'https://www.worldwildlife.org/',
	xq: 'https://xqsuperschool.org/',

	// media

	blavity: 'https://blavity.com/',
	fc: 'https://www.fastcompany.com/',
	goodup: 'https://www.upworthy.com/',
	mtv: 'https://www.mtv.com/',
	thedots: 'https://the-dots.com/',
	wsj: 'https://www.wsj.com/',

	// event
	hopin: 'https://hopin.com/'
}

const Logos = ({ logos }) => {
	return <div>
		{logos.map(logo =>
			<a
				key={logo}
				href={links[logo]}
				target="_blank"
				rel="noreferrer"
			>
				<img
					key={logo}
					className={styles.logo}
					src={logoContext(`./anthem-website-logos_${logo}.svg`).default}
				/>
			</a>
		)}
	</div>
}

const PartnerList = () => {
	return <div className={styles.root}>
		<div className={styles.heading}>Foundational Partners</div>
		<Logos logos={['adcouncil', 'bornthisway', 'feedingamerica', 'glaad', 'mozilla', 'naacp', 'nrdc', 'wwf', 'xq']} />
		<div className={styles.heading}>Media Partners</div>
		<Logos logos={['blavity', 'thedots', 'fc', 'goodup', 'wsj', 'mtv']} />
		<div className={styles.heading}>Event Partners</div>
		<Logos logos={['hopin']} />
	</div>
}

export default PartnerList
