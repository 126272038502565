import firebase from 'firebase/app'
import 'firebase/firestore'

const firebaseConfig = {
	apiKey: 'AIzaSyBYWeaP4EOl0gVh59sj-d0BgSnOpJhQaHg',
	authDomain: 'webbyreport-811f9.firebaseapp.com',
	databaseURL: 'https://webbyreport-811f9.firebaseio.com',
	projectId: 'webbyreport-811f9',
	storageBucket: 'webbyreport-811f9.appspot.com',
	messagingSenderId: '1029956129168',
	appId: '1:1029956129168:web:b865f4a6d14ef5bf29e315'
}

if (!firebase.apps.length) {
	firebase.initializeApp(firebaseConfig)
}

const db = firebase.firestore()

const funcs = {
	sendEvent (event) {
		window.dataLayer.push({
			event: event,
		})
	},
	sendPieceEvent (event, pieceID) {
		window.dataLayer.push({
			event: event,
			PieceID: pieceID,
		})
	},
	sendCreatedEvent (videoID, pieceID) {
		window.dataLayer.push({
			event: 'Video Created',
			VideoID: videoID,
			PieceID: pieceID,
		})
	},
	sendLogin (entrantID) {
		window.dataLayer.push({
			event: 'login',
			EntrantID: entrantID,
		})
	},
	videoView (videoID, videoPlayExtra) {
		const ref = db.collection('anthem_2022_events').doc(videoPlayExtra.replace('/', '-'))

		ref.get()
			.then(doc => {
				if (doc.exists) {
					ref.update({
						count: firebase.firestore.FieldValue.increment(1)
					})
				} else {
					ref.set({
						count: 1
					})
				}
			})

		// eslint-disable-next-line no-undef
		gtag('event', 'play_video', { play_count: 1, videoID, videoPlayExtra })
	},
}

export default funcs
